import React from "react";
import { BrandList } from "./style";
import BrandItem from "./components/BrandItem";
import Pagina from "../Pagina";
import { Container } from "../../sdk/Layout";

function BrandListComponent({ data, currentPage, limit, setPage }) {
    if (data == null) return null;
    return (
        <Container isFluid>
            <BrandList>
                {data.brands.map((index) => (
                    <BrandItem key={index._id} brand={index} />
                ))}
            </BrandList>
            <Pagina
                pages={data.pages}
                count={data.count}
                currentPage={currentPage}
                setPage={setPage}
                order={null}
                limit={limit}
            />
        </Container>
    );
}

export default React.memo(BrandListComponent);
