import React from "react";

function Icons({ icon, className }) {
	if (icon === "monitor-arrow-down") {
		return (
			<svg
				className={className}
				style={{ width: 24, height: 24 }}
				viewBox="0 0 24 24"
			>
				<path
					fill="currentColor"
					d="M21 16H3V4H21M21 2H3C1.9 2 1 2.9 1 4V16C1 17.1 1.9 18 3 18H10V20H8V22H16V20H14V18H21C22.1 18 23 17.1 23 16V4C23 2.9 22.1 2 21 2M16 10H13V6H11V10H8L12 14L16 10"
				/>
			</svg>
		);
	}

	if (icon === "image-frame") {
		return (
			<svg
				className={className}
				style={{ width: 24, height: 24 }}
				viewBox="0 0 24 24"
			>
				<path
					fill="currentColor"
					d="M10,14.29L6.5,19H17.46L14.75,15.46L12.78,17.8L10,14.29M5,21V7H18.96V21H5M12,2.4L14.61,5.03H9.37L12,2.4M5,5.03C4.5,5.03 4,5.22 3.61,5.61C3.2,6 3,6.46 3,7V21C3,21.5 3.2,22 3.61,22.39C4,22.8 4.5,23 5,23H18.96C19.5,23 19.96,22.8 20.37,22.39C20.77,22 21,21.5 21,21V7C21,6.46 20.77,6 20.37,5.61C19.96,5.22 19.5,5.03 18.96,5.03H16L12,1L7.96,5.03H5Z"
				/>
			</svg>
		);
	}

	if (icon === "sofa-outline") {
		return (
			<svg
				className={className}
				style={{ width: 24, height: 24 }}
				viewBox="0 0 24 24"
			>
				<path
					fill="currentColor"
					d="M21 9V7C21 5.35 19.65 4 18 4H14C13.23 4 12.53 4.3 12 4.78C11.47 4.3 10.77 4 10 4H6C4.35 4 3 5.35 3 7V9C1.35 9 0 10.35 0 12V17C0 18.65 1.35 20 3 20V22H5V20H19V22H21V20C22.65 20 24 18.65 24 17V12C24 10.35 22.65 9 21 9M14 6H18C18.55 6 19 6.45 19 7V9.78C18.39 10.33 18 11.12 18 12V14H13V7C13 6.45 13.45 6 14 6M5 7C5 6.45 5.45 6 6 6H10C10.55 6 11 6.45 11 7V14H6V12C6 11.12 5.61 10.33 5 9.78V7M22 17C22 17.55 21.55 18 21 18H3C2.45 18 2 17.55 2 17V12C2 11.45 2.45 11 3 11S4 11.45 4 12V16H20V12C20 11.45 20.45 11 21 11S22 11.45 22 12V17Z"
				/>
			</svg>
		);
	}

	if (icon === "help-circle-outline") {
		return (
			<svg
				className={className}
				style={{ width: 24, height: 24 }}
				viewBox="0 0 24 24"
			>
				<path
					fill="currentColor"
					d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z"
				/>
			</svg>
		);
	}

	if (icon === "school-outline") {
		return (
			<svg
				className={className}
				style={{ width: 24, height: 24 }}
				viewBox="0 0 24 24"
			>
				<path
					fill="currentColor"
					d="M12 3L1 9L5 11.18V17.18L12 21L19 17.18V11.18L21 10.09V17H23V9L12 3M18.82 9L12 12.72L5.18 9L12 5.28L18.82 9M17 16L12 18.72L7 16V12.27L12 15L17 12.27V16Z"
				/>
			</svg>
		);
	}

	if (icon === "text-box-edit-outline") {
		return (
			<svg
				className={className}
				// style={{ width: 24, height: 24 }}
				viewBox="0 0 24 24"
			>
				<path
					fill="currentColor"
					d="M10 21H5C3.89 21 3 20.11 3 19V5C3 3.89 3.89 3 5 3H19C20.11 3 21 3.89 21 5V10.33C20.7 10.21 20.37 10.14 20.04 10.14C19.67 10.14 19.32 10.22 19 10.37V5H5V19H10.11L10 19.11V21M7 9H17V7H7V9M7 17H12.11L14 15.12V15H7V17M7 13H16.12L17 12.12V11H7V13M21.7 13.58L20.42 12.3C20.21 12.09 19.86 12.09 19.65 12.3L18.65 13.3L20.7 15.35L21.7 14.35C21.91 14.14 21.91 13.79 21.7 13.58M12 22H14.06L20.11 15.93L18.06 13.88L12 19.94V22Z"
				/>
			</svg>
		);
	}

	if (icon === "arrow-right") {
		return (
			<svg
				className={className}
				style={{ width: 24, height: 24 }}
				viewBox="0 0 24 24"
			>
				<path
					fill="currentColor"
					d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z"
				/>
			</svg>
		);
	}

	return null;
}

export default Icons;
