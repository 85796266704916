import styled from "styled-components";
import InternalLink from "../InternalLink";

export const BrandList = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-right: -0.5%;
    margin-left: -0.5%;
`;

export const Brand = styled(InternalLink)`
    width: 19%;
    margin: 0.5%;

    @media (max-width: 980px) {
        width: 24%;
    }
    @media (max-width: 768px) {
        width: 49%;
    }
`;

export const Slider = styled.div`
    position: relative;
    overflow: hidden;
    &:hover {
        .slider {
            &-navigation,
            &-pagination {
                z-index: 1;
                opacity: 1 !important;
            }
        }
    }

    .slider {
        &-item {
            figure {
                position: relative;
                &:before {
                    content: "";
                    display: block;
                    padding-top: 80%;
                    @media (max-width: 768px) {
                        padding-top: 60%;
                    }
                }
                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        &-navigation {
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            transform: translateY(-50%);
            display: flex;
            justify-content: space-between;
            transition: all 200ms linear;
            opacity: 0;
            span {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 38px;
                height: 38px;
                background-color: rgba(255, 255, 255, 0.8);
                border-radius: 0 20px 20px 0;
                cursor: pointer;
                transition: all 200ms linear;
                &:hover {
                    background-color: rgba(255, 255, 255, 1);
                }
            }

            span:before {
                display: block;
                content: "";
                border: solid 1px ${(props) => props.theme.primary};
                border-right: 0;
                border-top: 0;
                width: 12px;
                height: 12px;
                transform: rotate(45deg);
                margin-left: 3px;
            }

            span:last-child {
                transform: rotate(180deg);
            }
        }
        &-pagination {
            position: absolute;
            top: 5px;
            padding-left: 5px;
            padding-right: 5px;
            left: 0;
            width: 100%;
            transition: all 200ms linear;
            opacity: 0;
            ul {
                padding: 0;
                margin: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                li {
                    margin: 5px;
                    display: block;
                    width: 100%;
                    height: 1px;
                    border-radius: 20px;
                    background-color: #fff;
                    opacity: 0.5;
                    transition: all 200ms linear;
                    &.active {
                        opacity: 1;
                        height: 2px;
                    }
                }
            }
        }
    }
`;

export const Info = styled.div`
    text-align: center;
    border: solid 1px ${(props) => props.theme.borderColor};
    border-top: 0;
    padding: 20px 0;
    .brand-logo {
        position: relative;
        margin: auto;
        background-color: #f8f8f8;
        width: 100px;
        height: 100px;
        border-radius: 100%;
        padding: 10px;
        margin-bottom: 10px;
        display: flex;
        img {
            width: 100%;
            height: auto;
            display: block;
            margin: auto;
            align-items: center;
            justify-content: center;
        }
        @media (max-width: 768px) {
            width: 70px;
            height: 70px;
        }
    }
    h3 {
        font-size: 14px;
        color: ${(props) => props.theme.textDefault};
    }
    .meta {
        padding-top: 5px;
        li {
            font-size: 12px;
            color: ${(props) => props.theme.gray};
        }
    }
`;
