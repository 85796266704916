import styled from "styled-components";
import { Container, Section, Heading } from "../sdk/Layout";

export const SectionExtended = styled(Section)`
    background-color: ${(props) => props.theme.grayLight};
`;

export const PostList = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-right: -1%;
    margin-left: -1%;
    margin: auto;

    @media (max-width: 1024px) {
        max-width: 650px;
    }

    @media (max-width: 520px) {
        justify-content: center;
    }
`;

export const Post = styled.article`
    width: 23%;
    margin: 1%;
    background-color: ${(props) => props.theme.white};
    border-radius: 3px;
    overflow: hidden;
    box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.2);

    @media (max-width: 1024px) {
        width: 48%;
    }

    @media (max-width: 520px) {
        width: 98%;
        max-width: 300px;
    }
`;

export const Text = styled.div`
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 250px;

    @media (max-width: 1220px) {
        min-height: 200px;
    }
`;

export const Image = styled.figure`
    position: relative;
    background-color: #112c55;
    &:before {
        content: "";
        display: block;
        padding-top: 79%;
    }
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
`;

export const Title = styled.h3`
    font-size: 20px;
    line-height: 24px;
    font-family: futura;
    color: ${(props) => props.theme.primaryDark};
    a {
        text-decoration: none;
        color: ${(props) => props.theme.primaryDark};
    }

    @media (max-width: 1220px) {
        font-size: 17px;
        line-height: 20px;
    }
`;

export const Author = styled.div`
    display: flex;
    align-items: center;
    margin-top: 10px;
    img {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        overflow: hidden;
        object-fit: cover;
        object-position: center;
        margin-right: 10px;
        border: solid 1px ${(props) => props.theme.borderColor};
    }
    p {
        margin: 0;
        padding: 0;
        font-size: 13px;
        color: ${(props) => props.theme.primaryDark};
    }
    span {
        color: ${(props) => props.theme.gray};
        font-size: 10px;
        font-weight: bold;
        display: block;
        padding-top: 5px;
    }
`;
